import adminUserStrings from './AdminUsersView';

export default {
  ...adminUserStrings,
  createOrder: 'Créer une commande',
  customerInformation: 'Renseignements sur le client',
  customer: 'client',
  vehicleInformation: 'Informations sur le véhicule',
  vehicle: 'Véhicule',
  pickupLocation: 'Lieu de prise en charge',
  pickup: 'pick-up',
  dropOffLocation: 'Lieu de dépôt',
  dropOff: 'dépôt',
  reviewTowOrder: 'Examiner l’ordre de remorquage',
  notReviewed: 'non examiné',
  submitTowOrder: 'Soumettre une commande de remorquage',
  tradeIn: 'commerce',
  locations: 'Emplacements',
  /** Customer Information step */
  pickupDateTimeRequested: 'Date / heure de ramassage demandée',
  serviceIsRequired: 'Le service est requis',
  selectedService: 'Service sélectionné',
  gatePass: 'Pass de porte',
  phoneNumber: 'Numéro de Téléphone',
  lotNumber: 'Numéro de lot',
  loadNumber: 'Numéro de chargement',
  auctionHouseInfo: 'Informations sur la maison de vente aux enchères',
  auctionHouseContactInformation:
    'Coordonnées de la maison de vente aux enchères',
  auctionHouseFirstNameIsRequired:
    'Le prénom du contact de la maison de vente aux enchères est requis',
  auctionHouseLastNameIsRequired:
    'Le nom de famille du contact de la maison de vente aux enchères est requis',
  auctionHouseMobilePhoneIsRequired:
    'Le téléphone portable de contact de la maison de vente aux enchères est requis',
  lotNumberIsRequired: 'Le numéro de lot est requis',
  loadNumberIsRequired: 'Le numéro de charge est requis',
  readyLogisticsContactInfo: 'Coordonnées de Ready Logistics',
  readyLogisticsEscalationEmailIsRequired:
    'Un numéro de téléphone d’escalade logistique prêt est requis',
  readyLogisticsEscalationEmailMustBeValid:
    'L’e-mail d’escalade logistique prêt doit être un e-mail valide',
  readyLogisticsEscalationFirstNameIsRequired:
    'Un prénom d’escalade logistique prêt est requis',
  readyLogisticsEscalationLastNameIsRequired:
    'Un prénom d’escalade logistique prêt est requis',
  readyLogisticsEscalationPhoneNumberIsRequired:
    'Un numéro de téléphone d’escalade logistique prêt est requis',
  customerContactInformation: 'Coordonnées du client',
  customerEmailMustBeValid: 'L’e-mail du client doit être un e-mail valide',
  customerFirstNameIsRequired: 'Le prénom du client est requis',
  customerLastNameIsRequired: 'Le nom de famille du client est requis',
  customerPhoneNumberIsRequired: 'Le numéro de téléphone du client est requis',
  specialInstructions: 'Instructions spéciales',
  thisFieldIsRequired: 'Ce champ est obligatoire',
  /** Purchase Vehicle step */
  purchaseVehicle: 'Acheter un véhicule',
  tradeVehicle: 'Véhicule commercial',
  makeIsRequired: 'La marque est requise',
  modelIsRequired: 'Le modèle est requis',
  licensePlateIsRequired:
    'La plaque d’immatriculation est requise. Indiquez « S.O. » s’il n’y a pas lieu.',
  yearIsRequired: 'L’année est requise',
  make: 'Faire',
  model: 'Modèle',
  color: 'Couleur',
  licensePlate: 'Plaque minéralogique',
  currentMileage: 'Kilométrage actuel',
  description: 'La Description',
  inoperable: 'Inopérable',
  back: 'Précédent',
  next: 'Prochain',
  /** Pickup Location step */
  pickupLocationIsRequired: 'Le lieu de prise en charge est requis',
  /** Drop Off Location step */
  dropOffLocationIsRequired: 'L’emplacement de dépôt est requis',
  transportNeeded: 'Transport nécessaire',
  transportDialogText: 'Nous avons remarqué que votre remorquage est plus long que le kilométrage recommandé pour un remorquage. Nous vous recommandons de démarrer un transport ; souhaitez-vous procéder à un transport',
  transportDialogTowServiceButtonText: 'Rester le service de remorquage',
  transportDialogTransportServiceButtonText: 'Passer au service de transport',
  transportTableHeaderMileageTier: 'Niveau de Milles',
  transportTableHeaderKilometerTier: 'Niveau de kilométrage',
  transportTableHeaderDeliveryEta: 'Délai de livraison estimé',
  transportServiceDialogKeepLightDutyRecovery: 'Le type de service restera Récupération légère',
  transportServiceDialogSwitchToLightDutyRecovery: 'Le type de service est revenu à Light Duty Recovery',
  transportServiceDialogSwitchToTransportService: 'Le type de service est maintenant',
  transportServiceDialogAutomaticSwitchToLightDutyRecoveryReasonStart: 'Le service est revenu à Light Duty Recovery car la distance est inférieure à',
  transportServiceDialogAutomaticSwitchToLightDutyRecoveryReasonEnd :'Miles ou le véhicule est inutilisable/le service a été modifié vers un pays non pris en charge',
  miles: 'Milles',
  kilometers: 'Kilometers',
  days:  'Jours',
  approved: 'Approuvé',
  denied: 'Refusé',
  transportServiceReviewScreenTitle: 'Service de transport approuvé',
  /** TradeIn Location step*/
  tradeInDropOffLocation: 'Échangez l’emplacement de dépôt',
  tradeInDropOffLocationIsRequired:
    'L’emplacement de dépôt d’échange est requis',
  sameAsPickupLocation: 'Identique à l’emplacement de prise en charge',
  /** Review Tow Order */
  towOrderInfo: 'Informations sur la commande de remorquage',
  orderDate: 'Date de commande',
  serviceRequested: 'Service demandé',
  distance: 'Distance',
  createDispatch: 'Créer une répartition',
  /** Submit Tow Order step */
  validateDropOffLocation: 'Valider l’emplacement de dépôt',
  validatePickupLocation: 'Valider le lieu de prise en charge',
  validateTradeInDropOffLocation: 'Valider l’échange dans le lieu de dépôt',
  addCustomerToDispatchSystem: 'Ajouter un client au système de répartition',
  createTowOrderInDispatchSystem:
    'Créer un ordre de remorquage dans le système d’expédition',
  attachAuctionHouseContactInfo:
    'Joindre les coordonnées de la maison de vente aux enchères',
  attachDocuments: 'Joindre des documents',
  attachReadyLogisticsContactInfo:
    'Joignez des informations de contact logistiques prêtes à l’emploi',
  pickupLocationWillBeValidatedToEnableDispatch:
    'Le lieu de prise en charge sera validé pour les détails requis afin de permettre l’expédition.',
  pickupLocationWillBeValidatedToEnableTowOrder:
    'Le lieu de ramassage sera validé pour les détails requis afin de permettre l’ordre de remorquage.',
  tradeInDropOffLocationWasValidated:
    'Le lieu de dépôt des échanges a été validé dans le système d’expédition.',
  tradeInDropOffLocationWillBeValidated:
    'Le lieu de dépôt des échanges sera validé pour les détails requis afin de permettre l’expédition.',
  customerAlongWithTheVehicleAndLocationsWillBeAdded:
    'Le lieu de dépôt des échanges sera validé pour les détails requis afin de permettre l’expédition.',
  withAllDataVerifiedTheTowOrderWillBeCreated:
    'Une fois toutes les données vérifiées, l’ordre de remorquage sera créé.',
  allAssociatedDocumentsAreBeingUploadedAndAttachedToTheTowOrder:
    'Tous les documents associés sont téléchargés et joints à l’ordre de remorquage.',
  allAuctionHouseContactInformationWillBeAttachedToTheTowOrder:
    'Toutes les coordonnées de la maison de vente aux enchères seront jointes à l’ordre de remorquage.',
  allReadyLogisticsContactInformationWillBeAttachedToTheTowOrder:
    'Toutes les informations de contact logistiques prêtes seront jointes à la commande de remorquage.',
  addingCustomerToDispatchSystem: 'Ajout d’un client au système d’expédition',
  customerWasAddedToDispatchSystem:
    'Le client a été ajouté dans le système d’expédition.',
  attachingDocumentsToTowOrder:
    'Joindre des documents à l’ordre de remorquage dans le système d’expédition',
  documentsAttachedToTowOrder: 'Documents joints à l’ordre de remorquage.',
  attachingAuctionHouseContactInformation:
    'Joindre les coordonnées de la maison de vente aux enchères à l’ordre de remorquage dans le système d’expédition.',
  auctionHouseContactInformationAttachedToTowOrder:
    'Coordonnées de la maison de vente aux enchères jointes à l’ordre de remorquage.',
  attachingReadyLogisticsContactInformationToTowOrder:
    'Joindre les coordonnées logistiques prêtes à l’emploi à l’ordre de remorquage dans le système d’expédition.',
  readyLogisticsContactInformationAttachedToTowOrder:
    'Coordonnées logistiques prêtes jointes à la commande de remorquage.',
  validatingPickupLocationInDispatchSystem:
    'Validation de l’emplacement de ramassage dans le système d’expédition',
  validatingTradeInDropOffLocation:
    'Validation de l’échange dans le lieu de dépôt dans le système d’expédition',
  validatingDropOffLocation:
    'Validation de l’emplacement de dépôt dans le système de répartition',
  dropOffLocationWasValidated:
    'Le lieu de dépôt a été validé dans le système de répartition.',
  creatingTowOrderInDispatchSystem:
    'Création d’un ordre de remorquage dans le système d’expédition',
  pickupLocationWasValidated:
    'Le lieu de prise en charge a été validé dans le système d’expédition.',
  dropOffLocationWillBeValidatedToEnableDispatch:
    'Le lieu de dépôt sera validé pour les détails requis afin de permettre l’expédition.',
  dropOffLocationWillBeValidatedToEnableTowOrder:
    'Le lieu de dépôt sera validé pour les détails requis afin de permettre l’ordre de remorquage.',
  towOrderHasBeenCompleted:
    'La commande de remorquage est terminée. Merci d’avoir choisi NSD!',
  wasCreated: 'a été créé dans le système de répartition',
  createAnother: 'Créer un autre',
  sendingOrderToKustomer: 'Envoyer la commande de remorquage à Kustomer',
  orderSuccessfullySentToKustomer: 'Commande de remorquage envoyée avec succès à Kustomer',
  dispatchClientPurchaseOrderId: 'Identifiant du bon de commande',
  creatingPurchaseOrder: 'Créer d\'un bon de commande',
  purchaseOrderWillBeCreated: 'Avec les détails d\'expédition de notre système, un bon de commande sera créé',
  purchaseOrderWasCreated: 'Le bon de commande a été créé',

  charactersLimitStart: 'Maximale de',
  charactersLimitEnd: 'caractères dépassés',
  charactersNotExactLength: 'Le champ doit être exactement',
  characters: 'characters',

  /* Added for Preferred locations */
  dropOffLocationInstructions: 'Instructions sur le lieu de dépôt',
  preferredLocations: 'Emplacements préférés',
  approximatedDistance: 'Distance approximative',
  state: 'État',
  address: 'Adresse',
  towDropOffAddress: 'Adresse de remorquage',
  timezone: 'Fuseau horaire',
  email: 'Email',
  phone: 'Téléphone',
  hoursOfOperation:'Heures d\'ouverture',
  towDropWeekdayHours: 'Dépannage en semaine',
  towDropWeekendHours: 'Heures de fin de semaine',
  
  isTheVehicleOperable: 'Le véhicule est-il opérationnel (la voiture démarre et sort du parc) ?',
};

export default {
  pageHeaders: {
    refresh: 'Rafraîchir',
  },
  charts: {
    download: 'Télécharger',
    moreOptions: 'Plus d\'options',
    moreCharts: 'Plus de graphiques',
    print: 'Imprimer',
    completedBy_fragment: 'Terminé par',
    total: 'Le total',
    clearSelection: 'Effacer la sélection',
    least: 'Moins',
    most: 'Plus',
    averages: 'Moyennes',
    averageDay: 'Moyenne/jour',
    day: 'Jour',
    export: 'Exporter',
  },
  detailTabs: {
    details: 'Des Détails',
    overview: 'Aperçu',
    notes: 'Remarques',
    photos: 'Photos',
    attachments: 'Pièces jointes',
    providerNotes: 'Notes du Fournisseur',
    internalNotes: 'Remarques Internes',
    closedLoop: 'Boucle Fermée',
    offerList: 'Liste d\'Offres',
    customerMessages: 'Messages envoyés au client',
    other: 'Autre',
  },
  dialog: {
    comments: 'Commentaires',
    customDateAndTime: 'Date et heure personnalisées',
    startDateTime: 'Date/heure de Début',
    endDateTime: 'Date/Heure de Fin',
    selectCustomDateAndTimeRange:
      'Sélectionnez une date et une plage horaire personnalisées',
    submit: 'Soumettre',
  },
  files: {
    selectFiles: 'Sélectionnez les fichiers',
    dropHere_fragment: 'Déposez les fichiers ici ou cliquez sur',
    browse_fragment: 'parcourir',
    throughYourMachine_fragment: 'à travers votre machine',
    sizeLimit: 'La limite de taille de fichier est',
    size: 'Taille',
  },
  form: {
    cancel: 'Annuler',
    emailMustBeValid: 'L\'email doit être valide',
  },
  location: {
    label: {
      canada: 'Canada',
      mexico: 'Mexique',
      unitedStates: 'États Unis',
    },
    state: 'État',
    states: 'États',
    province: 'Province',
    provinces: 'Provinces',
    country: 'Pays',
    addLocation: 'Ajouter un emplacement',
  },
  menu: {
    close: 'Proche',
    save: 'Sauvegarder',
  },
  options: {
    all: 'Tout',
    noOptions: 'Pas d\'optins',
  },
  reports: {
    lastUpdated: 'Dernière Mise à Jour',
    segment: 'Segment',
    service: 'Service',
    by_fragment: 'par',
  },
  table: {
    actions: 'Actions',
    details: 'Des Détails',
    rowsPerPage: 'Lignes par Page',
    of_N_Rows: 'de',
    more_than_N_Rows: 'plus qe',
    loadMore: 'Charger plus',
    newestToOldest_lowerCase: 'le plus récent-le plus ancien',
    oldestToNewest_lowerCase: 'le plus ancien-le plus récent',
    sortDisabledForTimeRange: 'Tri Désactivé pour la Plage de Temps',
    anyDate: 'N\'importe quelle Date',
    anyHour: 'N\'importe quelle Heure',
    dateCreated: 'Date Créée',
    sortBy: 'Trier Par',
    searchBy: 'Recherché Par',
    notes: 'Remarques',
    name: 'Nom',
    phone: 'Téléphone',
    email: 'E-mail',
    homePhone: 'Téléphone fixe',
    city: 'Ville',
    stateOrProvince: 'État / Province',
    state: 'État',
    address: 'Adresse',
    zipCode: 'Code postal',
    business: 'Entreprise',
    agentName: 'Nom d\'Agent',
    agentCode: 'Code de l\'Agent',
    product: 'Produit',
    plan: 'Plan',
    claimNumber: 'Numéro de réclamation',
    attachments: 'Pièces Jointes',
    fileName_aToZ: 'Nom de Fichier (a-z)',
    fileName_zToA: 'Nom de Fichier (z-a)',
    no: 'Non',
    yes: 'Oui',
    pagination: {
      firstPage: 'Première page',
      previousPage: 'Page précédente',
      nextPage: 'Page suivante',
      lastPage: 'Dernière page',
    },
  },
  time: {
    today: 'Aujourd\'hui',
    yesterday: 'Hier',
    last30Days: 'Les 30 Derniers Jours',
    last60Days: 'Les 60 Derniers Jours',
    last7Days: 'Les 7 derniers jours',
    last90Days: 'Les 90 Derniers Jours',
    allTime: 'Tout le temps',
    custom: 'Personnalisé',
    month: 'Mois',
    months: 'Mois',
    months_apr: 'Avr',
    months_aug: 'Aoû',
    months_dec: 'Déc',
    months_feb: 'Fév',
    months_jan: 'Jan',
    months_jul: 'Juil',
    months_jun: 'Juin',
    months_mar: 'Mar',
    months_may: 'Peu',
    months_nov: 'Nov',
    months_oct: 'Oct',
    months_sep: 'Sep',
    date: 'Date',
    week: 'Semaine',
    minutes: 'Minutes',
    hour: 'Heure',
    day: 'Jour',
    midnight: 'Minuit',
    noon: 'Le midi',
    year: 'Année',
  },
};
